import logo from './Logo.svg';
import img1 from './Asset 11.webp';
import img2 from './Asset 3.webp';
import plate1 from './Plate1.webp';
import full_control_img from './Take-full-control-of-your-weight-loss.webp';
import discover_things_img from './Discover things that.webp';
import we_use_magic_img from './We-use-the-magic-of-science-to-measure and-track-your-progress.webp';
import enjoy_recipes from './Enjoy Delicious Recipes.webp';
import first_class_nutrition from './First-class Nutrition.webp';
import learn_new_habits from './Learn New Habit.webp';
import about_dr_badi from './About-Dr-badi.webp';
import highlight_img from './Highlight_03.svg';
import telegram1 from './social-icons/telegram-1.svg';
import whatsapp1 from './social-icons/Whatsapp-1.svg';
import telegram from './social-icons/telegram.svg';
import whatsapp from './social-icons/Whatsapp.svg';
import linkedin from './social-icons/Linkedin.svg';
import instagram from './social-icons/instagram.svg';
import get_the_app from './how-it-works/Get the app.svg';
import starter_kit from './how-it-works/Receive your kit.svg';
import obtain_peronalized from './how-it-works/Obtain a personalised.svg';
import hassle_free from './how-it-works/Follow hassle-free guide.svg';
import weight_program from './how-it-works/Start Weight Stabilisation Program.svg';
import logo_footer from './logo-footer.png';
import globe from './globe.png';
import Slider from "react-slick";
import { useEffect } from 'react';
import './App.css';
import './gdpr.css';
import "slick-carousel/slick/slick.css";
import { useState } from 'react';
import CookieConsent from "react-cookie-consent";
import CookiePolicy from "./components/CookiePolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";


function App() {

  window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-XFS6XRTZL2');

  const [ready, setReady] = useState(false);
  const [section1_data, setSection1Data] = useState('');
  const [rawData, setRawData] = useState('');
  const [upperMenu, setUpperMenu] = useState({'Shop': {}, 'How_it_works': {}, 'About': {} });
  const [footerMenu, setFooterMenu] = useState({'Contact': {}, 'Privacy': {}, 'TOC': {}, 'Subscribe': {} });
  const [tilesDifferent, setTilesDifferent] = useState({'one': {}, 'two': {}, 'three': {}, 'four': {}});
  const [tilesDifferentReady, setTilesDifferentReady] = useState(false);
  const [tilesHowItWorks, setHowItWorks] = useState({'App': {}, 'Kit': {}, 'Plan': {}, 'Goals': {}, 'Program': {}});
  const [tilesHowitworksReady, setTilesHowitworksReady] = useState(false);
  const [menuReady, setMenuReady] = useState(false);
  const [footerReady, setFooterReady] = useState(false);
  const [sections, setSections] = useState({'en': [], 'ru': [], 'lv': [], 'es': []});
  const [lang, setLang] = useState('lv');
  const [options, setOptions] = useState({
    'red_splash': {"en": '-20px', 'ru': '-20px', 'lv': '-20px', 'es': '-20px'},
    'red_splash_mobile': {"en": '0', 'ru': '0', 'lv': '0', 'es': '0'},
  });

  const [showCookiePolicy, setShowCookiePolicy] = useState(false);
  
  function toggleShow(e) {
    e.preventDefault();
    setShowCookiePolicy(!showCookiePolicy);
  }
  
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  
  function togglePrivacyPolicy(e) {
    e.preventDefault();
    setShowPrivacyPolicy(!showPrivacyPolicy);
  }
  
  const [showTerms, setShowTerms] = useState(false);
  
  function toggleTerms(e) {
    e.preventDefault();
    setShowTerms(!showTerms);
  }

  const JoinUs = {
    'en': "Join us today",
    'ru': "Связаться",
    'lv': 'Pievienojies tagad',
    'es': 'Únete ahora' 
  };

  function processContent(content) {
    let tmp = content.split(' ');
      for(let i = 0; i < tmp.length; i++) {
        if(tmp[i].startsWith("**")) {
          tmp[i] = tmp[i].replace('**', '<span class="semi-bold">');
        }
        tmp[i] = tmp[i].replace('**', '</span>');
      }

      content = tmp.join(' ');

      return content;
  }

  function prepareContent(content) {
    let input = content.split("\n");

    for(let i = 0; i < input.length; i++) {
      if(input[i] != '') {
        input[i] = input[i].replace(/^/, '<p>').replace(/$/, '</p>'); 
      }
    };
    input = input.join(' ');

    return input;
  }

  useEffect(() => {
    if(!rawData) {
      return;
    }
    let i = 0;
    rawData.data.forEach(section => {
      let content = section.attributes.section_content_en;
      let title = section.attributes.section_title_en;

      if(content == null) {
        if(title) {
          sections['en'].push({'title': section.attributes.section_title_en, content: ''});
          sections['ru'].push({'title': section.attributes.section_title_ru, content: ''});
          sections['lv'].push({'title': section.attributes.section_title_lv, content: ''});
          sections['es'].push({'title': section.attributes.section_title_es, content: ''});

          setSections(sections);
        }
        return;
      }

      content = prepareContent(content);      
      content = processContent(content);
      sections['en'].push({'title': section.attributes.section_title_en, content: content});

      content = section.attributes.section_content_ru;
      content = prepareContent(content);
      content = processContent(content);
      sections['ru'].push({'title': section.attributes.section_title_ru, content: content});

      content = section.attributes.section_content_lv;
      content = prepareContent(content);
      content = processContent(content);
      sections['lv'].push({'title': section.attributes.section_title_lv, content: content});

      content = section.attributes.section_content_es;
      content = prepareContent(content);
      content = processContent(content);
      sections['es'].push({'title': section.attributes.section_title_es, content:content});

      setSections(sections);
    });

    setReady(true);

  }, [rawData]);

  // const base_url = 'https://cms.drew-k.com/';
  const base_url = 'https://backend.dokka.app/';
  
  useEffect(() =>{
    fetch(base_url + 'api/landing-sections')
      .then(res => res.json())
      .then(result => {
        setRawData(result);
    });

    fetch(base_url + 'api/what-makes-us-different-tiles')
      .then(res => res.json())
      .then(result => {
        let d = result.data;
        setTilesDifferent({
            'one': {'en': d[0].attributes.title_en, 'ru': d[0].attributes.title_ru, 'lv': d[0].attributes.title_lv, 'es': d[0].attributes.title_es },
            'two': {'en': d[1].attributes.title_en, 'ru': d[1].attributes.title_ru, 'lv': d[1].attributes.title_lv, 'es': d[1].attributes.title_es },
            'three': {'en': d[2].attributes.title_en, 'ru': d[2].attributes.title_ru, 'lv': d[2].attributes.title_lv, 'es': d[2].attributes.title_es },
            'four': {'en': d[3].attributes.title_en, 'ru': d[3].attributes.title_ru, 'lv': d[3].attributes.title_lv, 'es': d[3].attributes.title_es }
        });
        setTilesDifferentReady(true);
    });
    
    fetch(base_url + 'api/upper-menu-translations')
      .then(res => res.json())
      .then(result => {
        let d = result.data;
        setUpperMenu({
            'Shop': {'en': d[0].attributes.Shop_en, 'ru': d[0].attributes.Shop_ru, 'lv': d[0].attributes.Shop_lv, 'es': d[0].attributes.Shop_es },
            'How_it_works': {'en': d[0].attributes.How_it_works_en, 'ru': d[0].attributes.How_it_works_ru, 'lv': d[0].attributes.How_it_works_lv, 'es': d[0].attributes.How_it_works_es },
            'About': {'en': d[0].attributes.About_en, 'ru': d[0].attributes.About_ru, 'lv': d[0].attributes.About_lv, 'es': d[0].attributes.About_es },
        });
        setMenuReady(true);
    });
    
    fetch(base_url + 'api/footer-menus')
      .then(res => res.json())
      .then(result => {
        let d = result.data;
        setFooterMenu({
            'Contact': {'en': d[0].attributes.contact_us_en, 'ru': d[0].attributes.contact_us_ru, 'lv': d[0].attributes.contact_us_lv, 'es': d[0].attributes.contact_us_es },
            'Privacy': {'en': d[0].attributes.privacy_policy_en, 'ru': d[0].attributes.privacy_policy_ru, 'lv': d[0].attributes.privacy_policy_lv, 'es': d[0].attributes.privacy_policy_es },
            'TOC': {'en': d[0].attributes.terms_en, 'ru': d[0].attributes.terms_ru, 'lv': d[0].attributes.terms_lv, 'es': d[0].attributes.terms_es },
            'Subscribe': {'en': d[0].attributes.sign_up_en, 'ru': d[0].attributes.sign_up_ru, 'lv': d[0].attributes.sign_up_lv, 'es': d[0].attributes.sign_up_es },
        });

        setFooterReady(true);
    });
    
    fetch(base_url + 'api/how-does-it-work-tiles')
      .then(res => res.json())
      .then(result => {
        let d = result.data;

        setHowItWorks({
            'App': {
              'en': {
                'title': d[0].attributes.title_en,
                'content': d[0].attributes.description_en
              }, 
              'ru': {
                'title': d[0].attributes.title_ru,
                'content': d[0].attributes.description_ru
              },
              'lv': {
                'title': d[0].attributes.title_lv,
                'content': d[0].attributes.description_lv
              },
              'es': {
                'title': d[0].attributes.title_es,
                'content': d[0].attributes.description_es}
              },
            'Kit': {
              'en': {
                'title': d[1].attributes.title_en,
                'content': d[1].attributes.description_en
              },
              'ru': {
                'title': d[1].attributes.title_ru,
                'content': d[1].attributes.description_ru
              },
              'lv': {
                'title': d[1].attributes.title_lv,
                'content': d[1].attributes.description_lv
              },
              'es': {
                'title': d[1].attributes.title_es,
                'content': d[1].attributes.description_es}
              },
            'Plan': {
              'en': {
                'title': d[2].attributes.title_en,
                'content': d[2].attributes.description_en
              },
              'ru': {
                'title': d[2].attributes.title_ru,
                'content': d[2].attributes.description_ru
              },
              'lv': {
                'title': d[2].attributes.title_lv,
                'content': d[2].attributes.description_lv
              },
              'es': {
                'title': d[2].attributes.title_es,
                'content': d[2].attributes.description_es}
              },
            'Goals': {
              'en': {
                'title': d[3].attributes.title_en,
                'content': d[3].attributes.description_en
              },
              'ru': {
                'title': d[3].attributes.title_ru,
                'content': d[3].attributes.description_ru
              },
              'lv': {
                'title': d[3].attributes.title_lv,
                'content': d[3].attributes.description_lv
              },
              'es': {
                'title': d[3].attributes.title_es,
                'content': d[3].attributes.description_es}
              },
            'Program': {
              'en': {
                'title': d[4].attributes.title_en,
                'content': d[4].attributes.description_en
              },
              'ru': {
                'title': d[4].attributes.title_ru,
                'content': d[4].attributes.description_ru
              },
              'lv': {
                'title': d[4].attributes.title_lv,
                'content': d[4].attributes.description_lv
              },
              'es': {
                'title': d[4].attributes.title_es,
                'content': d[4].attributes.description_es}
              }
        });
        setTilesHowitworksReady(true);
    });

    fetch(base_url + 'api/options')
      .then(res => res.json())
      .then(result => {
        let new_options = result.data[0].attributes;
        setOptions({'red_splash': {"en": new_options.red_splash_en, 'ru': new_options.red_splash_ru,
                                  'lv': new_options.red_splash_lv, 'es': new_options.red_splash_es},
                    'red_splash_mobile': {"en": new_options.red_splash_en_mobile, 'ru': new_options.red_splash_ru_mobile,
                                          "lv": new_options.red_splash_lv_mobile, "es": new_options.red_splash_es_mobile}
                    });
    });
  }, []);

  const settings = {
    infinite: false,
    dots: false,
    prevArrow: false,
    nextArrow: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1110,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      }
    ]
  };

  const settingsSlide2 = {
    infinite: false,
    dots: false,
    prevArrow: false,
    nextArrow: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1110,
        settings: {
          slidesToShow: 3.3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    const onScroll = () => {
      let header = document.querySelector('header');
      if(window.scrollY > header.clientHeight) {
        header.classList.add('scrolled');
      } else {
        header.classList.remove('scrolled');
      }
    };
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  function openMenuMobile() {
    let menu = document.querySelector('#mySidenav');
    menu.classList.add('open');
  }

  function closeMenuMobile() {
    let scrolled = window.scrollY;
    let menu = document.querySelector('#mySidenav');
    menu.classList.remove('open');
    setTimeout(_ => {
      window.scrollTo(0, scrolled);
    }, 1);
  }

  function showLanguages(e) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  
    toggleMenu();
  }

  function toggleMenu() {
    let menu = getMenu();
    if(menu.classList.contains('open')) {
      closeMenu(menu);
    } else {
      openMenu(menu);
    }
  }

  function getMenu() {
    return document.querySelector('.lang-menu');
  }

  function closeMenu(menu) {
    document.querySelector('.lang-arrow').classList.remove('gg-chevron-up');
    document.querySelector('.lang-arrow').classList.add('gg-chevron-down');
    menu.classList.remove('open');
  }

  function openMenu(menu) {
    document.querySelector('.lang-arrow').classList.remove('gg-chevron-down');
    document.querySelector('.lang-arrow').classList.add('gg-chevron-up');
    menu.classList.add('open');
  }

  function changeLang(l) {
    setLang(l);
    let menu = getMenu();
    closeMenu(menu);
  }

  function scrollTo(selector) {
    setTimeout(_ => {
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth'
      });
    }, 10);
    
  }

  const script = document.createElement("script");

  script.src = "//embed.typeform.com/next/embed.js";
  script.async = true;

  document.body.appendChild(script);


  return (
    <div className="App">
      <style>
        {`
          .highlight-text {
            margin-left: ${options.red_splash_mobile[lang]}px;
          }

          @media (min-width: 1300px) {
            .highlight-text {
              margin-left: ${options.red_splash[lang]}px;
            }
          }
        `}
      </style>

      <header className="header">
          <div className="logo"><a href="/"><img src={logo} alt="Dr.Badi logo" /></a></div>

          <div id="mySidenav" className="sidenav">
            <a href="#" className="closebtn" onClick={closeMenuMobile}>&times;</a>
            <a href="#" onClick={() => {scrollTo('#how'); closeMenuMobile();}} dangerouslySetInnerHTML={{__html: menuReady ? upperMenu['How_it_works'][lang] : 'How it works' }}></a>
            <a href="#" dangerouslySetInnerHTML={{__html: menuReady ? upperMenu['Shop'][lang] : 'Shop' }}></a>
            <a href="#" onClick={() => {scrollTo('#about');closeMenuMobile();}} dangerouslySetInnerHTML={{__html: menuReady ? upperMenu['About'][lang] : 'About' }}></a>
            <a href="#" className='lang-chooser' onClick={showLanguages}><img src={globe} className="globe" />{lang.toUpperCase()} <i className="lang-arrow gg-chevron-down"></i></a>
            <ul className="lang-menu">
              {lang != 'en' ? <li onClick={() => {changeLang('en'); closeMenuMobile();}}>EN</li> : '' }
              {lang != 'ru' ? <li onClick={() => {changeLang('ru'); closeMenuMobile();}}>RU</li> : '' }
              {lang != 'lv' ? <li onClick={() => {changeLang('lv'); closeMenuMobile();}}>LV</li> : '' }
              {lang != 'es' ? <li onClick={() => {changeLang('es'); closeMenuMobile();}}>ES</li> : '' }
            </ul>
          </div>

          <a className="btn header-take-survey" data-tf-popup="qDq72LDV" data-tf-iframe-props="title=Dr.Badi Lat" data-tf-medium="snippet">Take a survey</a>

          <div className='hamburger-menu' onClick={openMenuMobile}>
            <div></div>
            <div></div>
            <div></div> 
          </div>

      </header>
        
      <section className="landing-section hero-section">
          <h1 className='hero-title'>
          <img src={highlight_img} className="highlight-text" />
          {ready ? <span dangerouslySetInnerHTML={{__html: sections[lang][0].title }}></span>: '' }
          {/* Lose weight <br /> and get healthy <span className="text-emphasize">without any hassle</span> */}
          </h1>
        {ready ? 
          <span dangerouslySetInnerHTML={{__html: sections[lang][0].content }}>
        </span> : ''}

        <aside className="join-us">
          <span className="bold join-us-heading">{JoinUs[lang]}</span>

          <div className="join-us-icons">
            <a href="https://t.me/drbadi_news" className='telegram-icon-header' onClick={() => {setTimeout(_ => {
              gtag('event', 'IDAM', {
                'event_category': 'button',
                'event_label': 'Telegram click',
              });
            }, 10); }} target="_blank">
              <img src={telegram1} alt="Join us in Telegram" />
            </a>
            <a href="https://wa.me/message/CIKEGGNM3YXLI1" onClick={() => {setTimeout(_ => {
              gtag('event', 'IDAM', {
                'event_category': 'button',
                'event_label': 'Whatsapp click',
              });
            }, 10); }} className='whatsapp-icon-header' target="_blank">
              <img src={whatsapp1} alt="Join us in Whatsapp" />
            </a>
          </div>
        </aside>

        <a className="btn section-take-survey" data-tf-popup="qDq72LDV" data-tf-iframe-props="title=Dr.Badi Lat" data-tf-medium="snippet">Take a survey</a>

        <section className="hero-images">
          <img className="img-hero img-1" src={img1} />
          <img className="img-hero img-2" src={img2} />
          <img className="img-hero img-3" src={plate1} />
        </section>
      </section>

      <section className="landing-section about">
        <a className='anchor' id="how"></a>
        <section className="h50">
          <h2 className="section-heading center-text how-it-works-title" dangerouslySetInnerHTML={{__html: ready ? sections[lang][1].title : " "}}></h2>
          

            <div className="slider-mobile">
              <div className='cards-wrpapper card-how-it-works'>
                <Slider {...settings}>
                  <div>
                    <div className='card get-the-app get-the-app-mobile'>
                      <img src={get_the_app} alt="Get the app" className='get_the_app'/>
                      <p className="card-header" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['App'][lang]['title'] : " " }}></p>
                      <p className="card-info" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['App'][lang]['content'] : " " }}></p>
                    </div>
                  </div>

                  <div>
                    <div className='card'>
                      <img src={starter_kit} alt="Get the starter kit" className='starter_kit'/>
                      <p className="card-header" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Kit'][lang]['title'] : " " }}></p>
                      <p className="card-info" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Kit'][lang]['content'] : " " }}></p>
                    </div>
                  </div>

                  <div>
                    <div className='card'>
                      <img src={obtain_peronalized} alt="Get the app" className='obtain_peronalized'/>
                      <p className="card-header" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Plan'][lang]['title'] : " " }}></p>
                      <p className="card-info" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Plan'][lang]['content'] : " " }}></p>
                    </div>
                  </div>

                  <div>
                    <div className='card'>
                      <img src={hassle_free} alt="Get the app" className='hassle_free'/>
                      <p className="card-header" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Goals'][lang]['title'] : " " }}></p>
                      <p className="card-info" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Goals'][lang]['content'] : " " }}></p>
                    </div>
                  </div>

                  <div>
                    <div className='card'>
                      <img src={weight_program} alt="Get the app" className='weight_program'/>
                      <p className="card-header" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Program'][lang]['title'] : " " }}></p>
                      <p className="card-info" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Program'][lang]['content'] : " " }}></p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>

            <div className="slider-desktop">
              <div className='cards-wrpapper card-how-it-works'>
                  <div className='card get-the-app get-the-app-desktop'>
                    <img src={get_the_app} alt="Get the app" className='get_the_app'/>
                    <p className="card-header" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['App'][lang]['title'] : " " }}></p>
                    <p className="card-info" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['App'][lang]['content'] : " " }}></p>
                  </div>

                  <div className='card'>
                    <img src={starter_kit} alt="Get the starter kit" className='starter_kit'/>
                    <p className="card-header" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Kit'][lang]['title'] : " " }}></p>
                    <p className="card-info" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Kit'][lang]['content'] : " " }}></p>
                  </div>

                  <div className='card'>
                    <img src={obtain_peronalized} alt="Get the app" className='obtain_peronalized'/>
                    <p className="card-header" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Plan'][lang]['title'] : " " }}></p>
                    <p className="card-info" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Plan'][lang]['content'] : " " }}></p>
                  </div>

                  <div className='card'>
                    <img src={hassle_free} alt="Get the app" className='hassle_free'/>
                    <p className="card-header" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Goals'][lang]['title'] : " " }}></p>
                    <p className="card-info" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Goals'][lang]['content'] : " " }}></p>
                  </div>

                  <div className='card'>
                    <img src={weight_program} alt="Get the app" className='weight_program'/>
                    <p className="card-header" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Program'][lang]['title'] : " " }}></p>
                    <p className="card-info" dangerouslySetInnerHTML={{__html: tilesHowitworksReady ? tilesHowItWorks['Program'][lang]['content'] : " " }}></p>
                  </div>
              </div>
            </div>


        </section>

        <div className='bgColor'></div>
        <section className="h50 section-about-wrapper">
          <h2 className="section-heading center-text what-makes-title" dangerouslySetInnerHTML={{__html: ready ? sections[lang][2].title : "What makes us different?"}}></h2>
          
          <div className="slider-mobile">
            <div className='cards-wrpapper difference-wrapper'>
              <Slider {...settingsSlide2}>
                <div>
                  <div className='card difference difference-decades'>
                    <p className="card-info" dangerouslySetInnerHTML={{__html: tilesDifferentReady ? tilesDifferent['one'][lang] : " " }}></p>
                  </div>
                </div>

                <div>
                  <div className='card difference difference-personalised'>
                    <p className="card-info" dangerouslySetInnerHTML={{__html: tilesDifferentReady ? tilesDifferent['two'][lang] : " " }}></p>
                  </div>
                </div>
                
                <div>
                  <div className='card difference difference-scientifically'>
                    <p className="card-info" dangerouslySetInnerHTML={{__html: tilesDifferentReady ? tilesDifferent['three'][lang] : " " }}></p>
                  </div>
                </div>

                <div>
                  <div className='card difference difference-guarantee'>
                    <p className="card-info" dangerouslySetInnerHTML={{__html: tilesDifferentReady ? tilesDifferent['four'][lang] : " " }}></p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

          <div className="slider-desktop">
            <div className='cards-wrpapper difference-wrapper'>

              <div className='card difference difference-decades'>
                <p className="card-info" dangerouslySetInnerHTML={{__html: tilesDifferentReady ? tilesDifferent['one'][lang] : " " }}></p>
              </div>

              <div className='card difference difference-personalised'>
                <p className="card-info" dangerouslySetInnerHTML={{__html: tilesDifferentReady ? tilesDifferent['two'][lang] : " " }}></p>
              </div>

              <div className='card difference difference-scientifically'>
                <p className="card-info" dangerouslySetInnerHTML={{__html: tilesDifferentReady ? tilesDifferent['three'][lang] : " " }}></p>
              </div>

              <div className='card difference difference-guarantee'>
                <p className="card-info" dangerouslySetInnerHTML={{__html: tilesDifferentReady ? tilesDifferent['four'][lang] : " " }}></p>
              </div>

            </div>
          </div>

        </section>

      </section>

      <section className="landing-section main-section full-control color-white">

          <img src={full_control_img} alt="Take full control of your weight loss" className="main-section-img"/>
          
          <main>
            <h2 className="section-heading" dangerouslySetInnerHTML={{__html: ready ? sections[lang][3].title : " " }}></h2>
            {ready ? 
              <span dangerouslySetInnerHTML={{__html: sections[lang][3].content }}>
            </span> : ''}
          </main>

      </section>

      <section className="landing-section main-section discover-things">

          <img src={discover_things_img} alt="Discover things that drives motivation" className="main-section-img"/>

          <main>
            <h2 className="section-heading" dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][4].title : '' }}></h2>
              <span dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][4].content : '' }}>
            </span>
          </main>

      </section>

      <section className="landing-section main-section we-use-magic color-white">

          <img src={we_use_magic_img} alt="We use the magic of science to measure and track your progress" className="main-section-img"/>

          <main>
            <h2 className="section-heading" dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][5].title : '' }}></h2>
            <span dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][5].content : '' }}>
            </span>
          </main>

      </section>

      <section className="landing-section main-section enjoy-recipes">

          <img src={enjoy_recipes} alt="Enjoy delicious recipes" className="main-section-img"/>

          <main>
            <h2 className="section-heading" dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][6].title : '' }}></h2>
            
            <span dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][6].content : '' }}>
            </span>
          </main>

      </section>

      <section className="landing-section main-section first-class-nutrition color-white">

          <img src={first_class_nutrition} alt="First-class nutrition" className="main-section-img"/>

          <main>
            <h2 className="section-heading" dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][7].title : '' }}></h2>
            <span dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][7].content : '' }}>
            </span>
          </main>

      </section>
      
      <section className="landing-section main-section learn-new-habits">

          <img src={learn_new_habits} alt="Learn new habits" className="main-section-img"/>

          <main>
            <h2 className="section-heading" dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][8].title : '' }}></h2>
            <span dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][8].content : '' }}>
            </span>
          </main>

      </section>

      <a className='anchor' id="about"></a>
      <section className="landing-section main-section about-dr-badi color-white">

          <img src={about_dr_badi} alt="About Dr.Badi" className="main-section-img"/>

          <main>
            <h2 className="section-heading w100" dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][9].title : '' }}></h2>
            <span dangerouslySetInnerHTML={{__html: sections[lang].length ? sections[lang][9].content : '' }}>
            </span>

            <a href="#" className='btn btn-white read-more'>Read more</a>
          </main>

      </section>

      <footer>
        <div className="upper-part">
          <a href="#!" onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'});}}><img src={logo_footer} alt="Dr.Badi logo" className='footer-logo'/></a>
          
          <section className='bottom-menu'>
            <ul className="footer-menu">
              <li dangerouslySetInnerHTML={{__html: menuReady ? upperMenu['Shop'][lang] : 'Shop' }}></li>
              <li><a href="#" onClick={() => scrollTo('#about')} dangerouslySetInnerHTML={{__html: menuReady ? upperMenu['About'][lang] : 'About' }}></a></li>
              <li><a href="#" onClick={() => scrollTo('#how')} dangerouslySetInnerHTML={{__html: menuReady ? upperMenu['How_it_works'][lang] : 'How it works' }}></a></li>
            </ul>

            <ul className="footer-menu">
              <li><a href="mailto:info@drbadi.app" dangerouslySetInnerHTML={{__html: footerReady ? footerMenu['Contact'][lang] : 'Contact Us' }}></a></li>
              <li><a href="#" onClick={(e) => {togglePrivacyPolicy(e);}} dangerouslySetInnerHTML={{__html: footerReady ? footerMenu['Privacy'][lang] : 'Privacy Policy' }}></a></li>
              <li><a href="#" onClick={(e) => {toggleTerms(e);}} dangerouslySetInnerHTML={{__html: footerReady ? footerMenu['TOC'][lang] : 'Terms & Conditions' }}></a></li>
            </ul>
          </section>

          <section className="newsletter">
            <p dangerouslySetInnerHTML={{__html: footerReady ? footerMenu['Subscribe'][lang] : 'Sign up to our newsletter' }}></p>

            <div className="email-field">
              <input  type="text" className="email-input" placeholder='Email address'/>
              <a href="#" className='btn'>Subscribe</a>
            </div>
          </section>
        </div>

        <div className="bottom-part">

          <div className="divider"></div>

          <div className='footer-social-icons'>
            <a href="https://t.me/drbadi_news" onClick={() => {setTimeout(_ => {
              gtag('event', 'IDAM', {
                'event_category': 'button',
                'event_label': 'Telegram click',
              });
            }, 10); }} target="_blank">
              <img src={telegram} alt="Dr.Badi Telegram"/>
            </a>
            <a href="https://wa.me/message/CIKEGGNM3YXLI1" onClick={() => {setTimeout(_ => {
              gtag('event', 'IDAM', {
                'event_category': 'button',
                'event_label': 'Whatsapp click',
              });
            }, 10); }} target="_blank">
              <img src={whatsapp} alt="Dr.Badi Whatsapp"/>
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/drbadi">
              <img src={linkedin} alt="Dr.Badi Linkedin"/>
            </a>
            <a target="_blank" href="https://www.instagram.com/dr.badidiet/">
              <img src={instagram} alt="Dr.Badi Instagram"/>
            </a>
          </div>

        </div>

        <CookieConsent>This website uses cookies to enhance the user experience. <a href="#" className="show" onClick={(e) => {toggleShow(e);}}>Cookie Policy</a> <CookiePolicy toggleShow={toggleShow} show={showCookiePolicy} /></CookieConsent>
        <PrivacyPolicy toggleShow={togglePrivacyPolicy} show={showPrivacyPolicy} />
        <Terms toggleShow={toggleTerms} show={showTerms} />
      </footer>

    </div>
  );
}

export default App;
