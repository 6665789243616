import './../css/cookie.css';

function CookiePolicy(props) {

    return(
        <>
            <div className={`show-policy ${props.show ? "visible" : ""}`}>
                <div className='cookie-wrapper'>
                    <div className='close' onClick={(e) => {props.toggleShow(e);}}>
                        x
                    </div>

                    <section>
                        <div>Effective as of: 2022-11-18 18:04:27</div>
                        <h3>COOKIE POLICY</h3>
                        <div>In this Cookie Policy we will provide you with detailed information on how Dr.Badi OÜ
                             (hereinafter - the &quot;we&quot; or &quot;our&quot;), shall undertake to ensure the security
                              of personal information and the protection of rights of the visitors and users of the websites 
                              (hereinafter - the &quot;Visitors&quot;, &quot;You&quot;) while you use our websites including
                               but not limited to https://drbadi.app (hereinafter - the &quot;Site&quot;) and the content on
                                it.</div>
                        <div>
                            <h3>1. What is a cookie?</h3>
                            <div>A cookie is a small file placed onto your device that enables our Site features and functionality.
                                 For example, cookies can enable us to identify your device and secure your access to the Site. Cookies
                                  also allow the Site to remember information about your browsing on the Site for a while and to recognize
                                   you the next time you visit the Site. All this allows us to give you the opportunity to use the Site 
                                   comfortably and to make the Site even more user-friendly.</div></div><div>
                                    <h3>2. Why do we use cookies?</h3><div >We use cookies for the following main 
                                        purposes:</div><ul><li>To ensure efficient and safe functioning of the Site.
                                             We use cookies to enable and support our security features, and to help 
                                             us detect malicious activity on our Site.</li><li>To understand, improve, 
                                                and research products, features, and services, including when you access
                                                 our Site from other websites or devices such as your computer or your 
                                                 mobile device.</li><li>To recognize the returning visitors of the Site. 
                                                    Cookies help us show you the right information and personalize your
                                                     experience. Cookies also help avoiding re-registration or re-filling
                                                      of the information by you each time you visit the Site.</li><li>To 
                                                        analyze your habits so that the functioning of the Site would be
                                                         convenient, efficient and would conform to your needs and 
                                                         expectations.</li><li>To measure the flows of the information
                                                             and data being sent to our Site. We use the cookies for 
                                                             accumulation of statistical data about the number of
                                                              users of the Site and their use of the Site.</li>
                    </ul></div><div>
                        <h3>
                            3. What cookies do we use?</h3><div >Each time you visit our Site, 
                            the long-term (persistent) cookies may be created, which stay in your browser after you 
                            sign-up and will be read by us when you return to our Site and not deleted after you finish 
                            browsing our Site, and the short-term (session) cookies, which expire or are deleted after
                             you finish browsing our Site (i.e. they usually last during the current visit to our Site
                              or browsing session).</div><div>Cookies used by the
                               Company:</div><ul><li>Strictly required or necessary cookies. These cookies 
                                are required for the operation of our Site. They include, for example, 
                                cookies that enable storage of information filled by you during the 
                                browsing session, enable you to log into secure areas of our Site. 
                                Without these cookies operation of the Site would be impossible or 
                                its functioning may be severely affected.</li><li>Preferences cookies. 
                                    These improve the functional performance of our Site and make it
                                     easier for you to use. These cookies remember the settings selected by
                                      the Visitors (for example, the settings of language or currency). With
                                       the use of these cookies, the Visitors may avoid the changes of settings
                                        during each visit of the Site. These cookies also remember changes made
                                         by you in the Site (for example, in case you leave comment on the Site). 
                                         These cookies do not track your behavior in other websites.</li><li>Analytics
                                             and Statistics. These cookies show us if the Visitor has visited our Site
                                              before. The analytic cookies allow us to recognize and count the number 
                                              of users of our website and see how such users navigate through our Site.
                                               We also use cookies to understand, improve, and research products, 
                    features, and services. For instance, analytical cookies may show us, which websites are 
                    visited more frequently, help us to record dysfunctionalities of the Site, etc.</li></ul>
                    <div >Third-party cookies:</div>
                    <ul><li>We use Google Analytics, a web analysis service provided by Google,
                         Inc (hereinafter referred to as &quot;Google&quot;). The information 
                         collected by Google Analytics is transmitted to and stored with Google. 
                         Google may transmit the information collected by Google Analytics to the
                          third parties as required by the law or when those third parties process
                           the information in the name of Google. We recommend consulting the Google
                            Privacy and Cookies Policy on a separate and regular basis.</li></ul>
                            </div><div>
                                <h3>4. How to
                                 refuse or block cookies?</h3><div >Many web browsers
                                  are set so that they would automatically accept all cookies.</div>
                                  <div >You may refuse and make a preference 
                                  which cookies you allow by choosing it in our Cookie Consent Banner.
                                   Also all the cookies will be set if you accept all by clicking 
                                   &quot;Accept All&quot; on our Cookie Consent Banner.</div>
                                   <div>The Visitors may, at their discretion, 
                                   manage, block or delete cookies, if the settings of their browser or
                                    device enable them to do so. Nevertheless, if you refuse or block the 
                                    cookies or other similar technologies, some functions of the Site may
                                     be inaccessible to you or they may operate not properly.</div>
                                     <div >We draw your attention that necessary 
                                     cookies are critical for functioning of our Site, and in case of your
                                      objections, some features of the Site may not work or may not work
                                       properly.</div><div>You may require that we
                                        delete all the data about you, as collected and processed with the 
                                        help of the cookies, by contacting to the email address legal@drbadi.app.
                                        </div><div>You can opt out of Google Analytics
     without affecting how you visit our Site. For more information on opting out of being tracked by
      Google Analytics across all websites you use, visit this Google 
      page: https://tools.google.com/dlpage/gaoptout.</div><div>
        You may find more information about how to delete cookies, as well as the other useful information 
        related to the use of the cookies, on the website http://www.allaboutcookies.org/.</div></div>
        <div><h3>
            5. Do we update Cookie Policy?</h3><div >This Cookie Policy may be updated by us
             from time to time. We will inform you about the updates, by providing the new version of the Cookie 
             Policy. For this reason, we recommend you periodically visit our Site, where you will always find the latest version of this Cookie Policy. This Policy was created with the help of the <a  href="https://termshub.io?utm_source=referral&amp;utm_medium=generated_documents&amp;utm_campaign=referral_documents&amp;utm_content=cp_th_text" target="_blank">TermsHub.io</a> and the <a  href="https://termshub.io/cookie-policy?utm_source=referral&amp;utm_medium=generated_documents&amp;utm_campaign=referral_documents&amp;utm_content=cp_th_text" target="_blank"> Cookie Policy</a> Generator.</div><div >This Cookie Policy 
    shall be applied from the date of announcement of it on the Site.</div></div>
                    </section>

                </div>
            </div>
        </>
    );

}

export default CookiePolicy;